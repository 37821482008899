<template>
  <path
    d="M15 22C15 21.4477 15.4477 21 16 21H33C33.5523 21 34 21.4477 34 22V34C34 35.1046 33.1046 36 32 36H17C15.8954 36 15 35.1046 15 34V22Z"
    fill="#3769D6"
  />
  <path
    d="M18 36H31V26C31 24.8954 30.1046 24 29 24H20C18.8954 24 18 24.8954 18 26V36Z"
    fill="#EDF4FE"
  />
  <path
    d="M23.3922 9.34314C24.0399 8.88562 24.9602 8.88562 25.6079 9.34314L35.3309 16.2113C36.64 17.136 35.8994 19 34.223 19H14.777C13.1006 19 12.36 17.136 13.6691 16.2113L23.3922 9.34314Z"
    fill="#92AFEA"
  />
  <path
    d="M20 30.3333C20 30.1492 20.1492 30 20.3333 30H28.6667C28.8508 30 29 30.1492 29 30.3333C29 30.7015 28.7015 31 28.3333 31H20.6667C20.2985 31 20 30.7015 20 30.3333Z"
    fill="#92AFEA"
  />
  <path
    d="M20 28.3333C20 28.1492 20.1492 28 20.3333 28H28.6667C28.8508 28 29 28.1492 29 28.3333C29 28.7015 28.7015 29 28.3333 29H20.6667C20.2985 29 20 28.7015 20 28.3333Z"
    fill="#92AFEA"
  />
  <path
    d="M20 26.3333C20 26.1492 20.1492 26 20.3333 26H28.6667C28.8508 26 29 26.1492 29 26.3333C29 26.7015 28.7015 27 28.3333 27H20.6667C20.2985 27 20 26.7015 20 26.3333Z"
    fill="#92AFEA"
  />
</template>
